<template>
  <span class="space-x-2">
    <component
      :is="iconSvg"
      :class="iconCss"
    ></component>
    <span
      v-if="showText"
      class="text-lg font-medium relative top-0.5 shadow-text"
      >{{ textToShow }}</span
    >
  </span>
</template>

<script>
import YEN from '@/components/icons/yen.icon.vue';
import ETH from '@/components/icons/eth.icon.vue';
import Ethereum from '@/components/icons/eth.icon.vue';
import MATIC from '@/components/icons/matic.icon.vue';
import Polygon from '@/components/icons/matic.icon.vue';
import BNB from '@/components/icons/bnb.icon.vue';
import BNBSmartChain from '@/components/icons/bnb.icon.vue';
import ASTR from '@/components/icons/astr.icon.vue';
import AstarNetwork from '@/components/icons/astr.icon.vue';
import AVAX from '@/components/icons/avax.icon.vue';
import Avalanche from '@/components/icons/avax.icon.vue';
import OAS from '@/components/icons/oas.icon.vue';
import TCG from '@/components/icons/tcgt.icon.vue';
import HOMEDSE from '@/components/icons/homeverse.icon.vue';
import TCGVerse from '@/components/icons/tcgt.icon.vue';
import HOMEVerse from '@/components/icons/homeverse.icon.vue';
import MCH from '@/components/icons/mch.icon.vue';
import MCHVerse from '@/components/icons/mch.icon.vue';
import CICC from '@/components/icons/cicc.icon.vue';
const SymbolMap = {
  YEN,
  OAS,
  ETH,
  Ethereum,
  MATIC,
  Polygon,
  BNB,
  BNBSmartChain,
  ASTR,
  AstarNetwork,
  SBY: ASTR,
  AVAX,
  TCG,
  HOMEDSE,
  MCH,
  TCGC: TCG,
  Avalanche,
  TCGVerse,
  HOMEVerse,
  MCHVerse,
  MCHC: MCH,
  CICC,
};
const TextMap = {
  HOMEDSE: 'OAS',
  TCG: 'OAS',
  MCH: 'OAS',
};
export default {
  props: {
    symbol: {
      type: String,
      required: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    iconCss: {
      type: String,
      default: () => 'w-7 h-7 inline-block',
    },
    useDefaultSymbol: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      SymbolMap,
      TextMap,
    };
  },
  computed: {
    iconSvg() {
      return this.SymbolMap[this.symbol];
    },
    textToShow() {
      return this.useDefaultSymbol && this.TextMap[this.symbol]
        ? this.TextMap[this.symbol]
        : this.symbol;
    },
  },
};
</script>

<style lang="scss">
.shadow-text {
  text-shadow: 0px 3px 3px #000000;
}
</style>
